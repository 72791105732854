import {
  Button, Dropdown, MenuProps, Row,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React, { FC, useEffect, useState } from 'react';
import { Header } from 'antd/es/layout/layout';
import { Link } from 'react-router-dom';
import { REDIRECT_TO } from '../../../config/constants';
import { getCurrentRoute } from '../../../services';
import {
  getProjectName,
  getProjects,
} from '../../../selectors';
import { Icon, SideMenu } from '../../atoms';
import { getContextStores } from '../../../context';
import styles from './styles.module.less';

const MenuHeader: FC<React.PropsWithChildren<{}>> = ({ children }) => {
  /* Context  */
  const contextStores = getContextStores();
  const { layoutState } = contextStores;
  const { presenterMode } = layoutState;
  /* Selectors */
  const projectName = getProjectName(contextStores);
  const projects = getProjects(contextStores);

  const [width, setWidth] = useState(window.innerWidth);

  const getProjectSwitchUrl = (requestedProjectId: string) => {
    if (!requestedProjectId) return '';
    const currentRoute = getCurrentRoute();
    const newPath = currentRoute?.path;
    return `/${requestedProjectId}?`
      + `${REDIRECT_TO}=${encodeURIComponent(newPath)}`;
  };

  const getProjectSwitchItem = (
    { name, id, tenant }: { name: string, id: string, tenant: string },
  ) => ({
    label: <a href={getProjectSwitchUrl(id)}>{`${name} (${tenant})`.toUpperCase()}</a>,
    key: id,
  });

  let menuProjects: MenuProps['items'] = projects?.filter(
    ({ isArchived }) => !isArchived,
  ).map(getProjectSwitchItem);

  const menuArchivedProjects: MenuProps['items'] = projects?.filter(
    ({ isArchived }) => isArchived,
  ).map(getProjectSwitchItem);

  if (menuArchivedProjects.length) {
    menuProjects = [
      ...menuProjects,
      {
        label: <span className={styles.archivedLabel}>Archived</span>,
        key: 'archived',
        children: menuArchivedProjects,
      },
    ];
  }

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  const renderLeftCol = () => (
    <>
      <div className={styles.title}>
        {!presenterMode && <SideMenu />}
        <Link to="/">
          <Icon name="ynomiaFullLogoDark" style={{ width: 130 }} id="ynomia-logo-menu-header" />
        </Link>
      </div>
      <div className={styles.menuProject}>
        <Dropdown menu={{ items: menuProjects }} placement="bottomLeft">
          <Button style={{ height: 40 }}>
            {projectName?.toUpperCase() || ''}
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    </>
  );

  const renderHeaderOnMultipleLine = () => (
    <div>
      <Row className={styles.containerMultiple} gutter={[16, 4]}>
        {renderLeftCol()}
      </Row>
      {!presenterMode && (
        <div style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 15 }}>
          {children}
        </div>
      )}
    </div>
  );

  const renderHeader = () => (
    <div className={styles.container}>
      {renderLeftCol()}
      <div className={styles.childrenColumn}>{children}</div>
    </div>
  );

  if (presenterMode) {
    return (
      <Header className={styles.headerPresenterMode}>
        <div className={styles.containerPresenterMode}>
          <div className={styles.title}>
            <Link to="/">
              <Icon name="ynomiaFullLogoDark" style={{ width: 130 }} id="ynomia-logo-menu-header" />
            </Link>
          </div>
          <span className={styles.projectTitlePresenterMode}>
            {projectName?.toUpperCase() || ''}
          </span>
          {children}
        </div>
      </Header>
    );
  }

  return (
    <Header className={styles.header}>
      {width > 1239 ? renderHeader() : renderHeaderOnMultipleLine()}
    </Header>
  );
};

export default MenuHeader;
