/* irrespective of the environment, the following configuration remains constant */
import {
  IconDefinition,
  faBook,
  faChartLine,
  faClipboardList,
  faCubes,
  faFileCsv,
  faGear,
  faMap,
  faMoneyBillWave,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { DifferOptions } from 'json-diff-kit';
import { PlanResultBand } from '@ynomia/core/dist/blueprint';
import { SET_DONT_SHOW_PRESENTER_SETTING } from '../../actions/actionTypes';
import { AssetTableColumn } from '../types';

export type Category = {
  id: string,
  label: string,
};

export const categories: Array<Category> = [
  {
    id: 'activity',
    label: 'ACTIVITY',
  },
  {
    id: 'project setup',
    label: 'PROJECT SETUP',
  },
];

export const DEFAULT_PRETTY_MAC_ADDR_TRUNCATION = 4;

export type Route = {
  id: string,
  path: string,
  label: string,
  icon: IconDefinition,
};

export const PROJECT_SLUG = ':projectSlug';

export const routes: Array<Route> = [
  {
    id: 'status_tracking',
    path: `/${PROJECT_SLUG}/status-tracking`,
    label: 'Status Tracking',
    icon: faMap,
  },
  {
    id: 'user_management',
    path: `/${PROJECT_SLUG}/user-management`,
    label: 'User Management',
    icon: faUser,
  },
  {
    id: 'asset_update_tool',
    path: `/${PROJECT_SLUG}/asset-update-tool`,
    label: 'Asset Update Tool',
    icon: faFileCsv,
  },
  {
    id: 'status_update_tool',
    path: `/${PROJECT_SLUG}/status-update-tool`,
    label: 'Status Update Tool',
    icon: faFileCsv,
  },
  {
    id: 'models',
    path: `/${PROJECT_SLUG}/models`,
    label: 'Models',
    icon: faCubes,
  },
  {
    id: 'configuration',
    path: `/${PROJECT_SLUG}/configuration`,
    label: 'Configuration',
    icon: faGear,
  },
  {
    id: 'powerbi',
    path: `/${PROJECT_SLUG}/metrics-insights`,
    label: 'Metrics & Insights',
    icon: faChartLine,
  },
];

export const routeIcons = {
  faBook,
  faClipboardList,
  faCubes,
  faFileCsv,
  faGear,
  faMap,
  faMoneyBillWave,
  faUser,
};

export const routesKeyedById = new Map(routes.map(route => [route.id, route]));

/**
 * @ynomia/client configuration constants.
 */
export const YNOMIA_CLIENT_SDK_NAME = 'webhqv2';
export const ACCESS_TOKEN_STORAGE_KEY = 'access_token';
export const ID_TOKEN_STORAGE_KEY = 'id_token';
export const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';
export const PROJECT_ID_STORAGE_KEY = 'project_id';
export const REDIRECT_TO = 'redirect_to';
export const LOGIN_HINT = 'login_hint';

export const REGION = 'region';

/**
 * Periodic data fetching intervals.
 * 30 sec countdown, but +1s for visual effect.
 */
export const DEFAULT_FETCH_INTERVAL = 31000;
export const LAYER_AND_BOOTSTRAP_FETCH_INTERVAL = 60 * 60 * 1000;

export const DEFAULT_STATUS_COLOR = '#aaaaaa';

export type AssetUpdateMode = 'complete' | 'upsert' | 'append';
export type StatusUpdateMode = 'default';

export const IS_MOBILE = window.innerWidth < 768;

export const INITIAL_DONT_SHOW_PRESENTER_SETTING = (/true/)
  .test(`${localStorage.getItem(SET_DONT_SHOW_PRESENTER_SETTING)}`);

export const USER_INITIATED = 'user_initiated';
export const EXPIRED = 'expired';
export const USER_UNKNOWN = 'user_unknown';

export const BLUEPRINT_SLICE_NAMES = [
  'asset_types',
  'calendar',
  'features_mobile',
  'features',
  'integrations',
  'layer_types',
  'metadata',
  'project_flags_mobile',
  'reports_v2',
  'role_types',
  'site_templates',
] as Array<string>;

export const differOptions: DifferOptions = {
  detectCircular: true,
  maxDepth: Infinity,
  showModifications: true,
  arrayDiffMethod: 'lcs',
};

export const PRESET_TWIN_ID_NAME = ['twinID', 'Ynomia Twin ID', 'Model Location Reference (MLR)'];

export const STATUS_TRACKING_PAGE_FEATURES = {
  digitalTwin: true,
  dateRange: true,
  assetStatusSummary: true,
  plan: true,
  fieldSummaries: true,
  observationSummary: true,
  layerSummary: true,
  timeTravel: true,
  layoutSwitcher: true,
  assetDetails: true,
  assetActions: true,
  createAsset: true,
  exportReports: true,
  assetSelection: true,
};

export const DEFAULT_ASSET_PAGE_FEATURES = {
  digitalTwin: false,
  dateRange: false,
  assetStatusSummary: false,
  plan: false,
  fieldSummaries: true,
  observationSummary: true,
  layerSummary: false,
  timeTravel: false,
  layoutSwitcher: true,
  assetDetails: false,
  assetActions: false,
  createAsset: false,
  exportReports: false,
  assetSelection: false,
};

export const CUSTOM_DATE_COLUMNS = ['formatDate', 'observationTypeDate'];

export const DEFAULT_COLUMNS:Array<AssetTableColumn> = [
  {
    key: 'label',
    column_order: {
      full_view: 1,
      small_view: 1,
    },
    title: 'Name',
    filter_enabled: false,
    sort_enabled: true,
  },
  {
    key: 'unit_type',
    title: 'Unit Type',
    column_order: {
      full_view: 2,
      small_view: 2,
    },
    filter_enabled: true,
    sort_enabled: true,
  },
  {
    key: 'supplier',
    title: 'Supplier',
    column_order: {
      full_view: 3,
      small_view: 3,
    },
    filter_enabled: true,
    sort_enabled: true,
  },
  {
    title: 'Level',
    key: 'destination',
    column_order: {
      full_view: 4,
      small_view: 4,
    },
    filter_enabled: true,
    sort_enabled: true,
  },
  {
    title: 'Load No.',
    key: 'load_number',
    column_order: {
      full_view: 5,
      small_view: 5,
    },
    filter_enabled: true,
    sort_enabled: true,
  },
  {
    title: 'Status',
    key: 'status',
    column_order: {
      full_view: 6,
      small_view: 6,
    },
    filter_enabled: false,
    sort_enabled: true,
  },
  {
    title: 'Achieved At',
    key: 'statusUpdatedDate',
    column_order: {
      full_view: 7,
      small_view: 7,
    },
    custom: 'formatDate',
    filter_enabled: true,
    sort_enabled: true,
  },
];

export const PLAN_COLUMNS: Array<AssetTableColumn> = [
  {
    title: 'PvA Status',
    key: 'planStatus',
    column_order: {
      full_view: 0,
      small_view: 0,
    },
    custom: 'planStatus',
    filter_enabled: false,
    sort_enabled: true,
  },
  {
    title: 'Next Planned Status',
    key: 'plannedStatus',
    column_order: {
      full_view: 0,
      small_view: 0,
    },
    filter_enabled: true,
    sort_enabled: true,
  },
  {
    title: 'Next Planned Due Date',
    key: 'plannedDueDate',
    column_order: {
      full_view: 0,
      small_view: 0,
    },
    custom: 'formatDate',
    filter_enabled: true,
    sort_enabled: true,
  },
];

export const UNKNOWN_BAND: PlanResultBand = {
  id: 'unknown',
  label: 'Unknown',
  color: '#e1e1e1',
  minDaysUntilDueDateThreshold: 0,
};

export const CURRENTLY_TOOLTIP_DATE_FILTER_TEXT = `
  Filtering current status(es) with an active date range filter 
  will display assets that [A] have achieved the selected
  status(es) in the active date range filter AND
  [B] are currently in the status.`;

export const CURRENTLY_TOOLTIP_TEXT = `These Numbers and Percentages represent all Assets 
  that are CURRENTLY in this exact Status. 
  Click to filter the dashboard to show only these Assets.
  Note: Previously referred to as "Current".`;

export const COMPLETED_TOOLTIP_TEXT = `These Numbers and Percentages represent all 
  Assets that have any record of this Status in their History,
  whether it be the Current Status or Achieved at any earlier time.
  Click to filter the dashboard to show only these Assets. 
  Note: Previously referred to as "Cumulative".`;

export const COMPLETED_TOOLTIP_DATE_FILTER_TEXT = `Filtering cumulative 
  status(es) with an active date range filter 
  will display assets that [A] have achieved the selected
  status(es) in the active date range filter.`;
