import 'chartist/dist/index.css';
import maxBy from 'lodash/maxBy';
import ChartistButtonLabels from '../plugins/chartist-button-labels';
import ChartistTooltip from '../plugins/chartist-tooltip';
import { DEFAULT_STATUS_COLOR } from '../config/constants';
import { getTextWidth } from '.';

const positionX = cxt => (cxt.x1 + cxt.x2) / 2;

const positionY = cxt => (cxt.y1 + cxt.y2) / 2;

// https://stackoverflow.com/a/11868398/2474359
export const colorIsDark = (hexcolor: string) => {
  const colorStr = hexcolor.replace('#', '');
  const r = parseInt(colorStr.slice(0, 2), 16);
  const g = parseInt(colorStr.slice(2, 4), 16);
  const b = parseInt(colorStr.slice(4, 6), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 10)) / 1000;
  return yiq <= 128;
};

export const sectionSetupChart = (
  context: any,
  colorScheme: { [label: string]: string },
  isFocusedOnLevel: boolean,
  handleLevelSelect: (index: number) => void,
) => {
  const color = colorScheme[context.meta as string] || DEFAULT_STATUS_COLOR;

  if (context.type === 'bar') {
    if (isFocusedOnLevel) {
      context.element.attr({
        style: `stroke: ${color}; stroke-width: 20px; cursor: pointer;`,
      });
    } else {
      context.element.attr({
        style: `stroke: ${color}; opacity: 0.3; stroke-width: 20px; cursor: pointer;`,
      });
    }
    // Attach click handlers
    context.element._node.onclick = () => {
      handleLevelSelect(context.index);
    };
  }

  // Add labels to bars
  if (!window.location.href.includes('disableBarLabels')) {
    const len = context.x2 - context.x1;
    if (context.type === 'bar' && context.value.x && len > 10) {
      const textColor = colorIsDark(color) ? '#FFFFFF' : '#222222';
      context.group
        .elem(
          'text',
          {
            // This gets the middle point of the bars and then adds the
            // optional offset to them
            x: positionX(context),
            y: positionY(context) + 4,
            style: `
              fill: ${textColor};
              font-size: 0.75rem;
              pointer-events: none;
              text-anchor: middle;
            `,
          },
          'ct-bar-label',
        )
        .text(context.value.x as unknown as string);
    }
  }
};

export const getChartOptions = (
  labels: Array<string>,
  buttonLabelsPluginOptions?: { onclick?: (label: string) => void, labels?: Array<string> },
): any => {
  const toolTipPlugin = ChartistTooltip(
    {
      anchorToPoint: false,
      appendToBody: false,
      seriesName: false,
    },
  );

  const buttonLabelsPlugin = ChartistButtonLabels(buttonLabelsPluginOptions);

  const font = '.76rem Inter,sans-serif';
  const largestLabel = maxBy(labels, a => getTextWidth(a, font));
  const width = largestLabel ? (getTextWidth(largestLabel, font) + 10) : 60;

  return {
    seriesBarDistance: 12,
    reverseData: true,
    stackBars: true,
    height: `${labels.length * 32 + 50}px`,
    fullWidth: true,
    horizontalBars: true,
    chartPadding: {
      top: 0, right: 0, left: 0, bottom: 0,
    },
    plugins: [
      toolTipPlugin,
      buttonLabelsPlugin,
    ],
    axisX: { onlyInteger: true },
    axisY: {
      offset: width,
    },
  };
};
